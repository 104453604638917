import 'jquery'
import $ from 'jquery';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-icons/font/bootstrap-icons.css'
import '../css/style.scss'
import {createHome} from "./ui/home";
import {clock, createDate} from "./time/time"
import {fillData} from "./ui/table";

import {DataTable} from "simple-datatables"
import button from "bootstrap/js/src/button";
import {toggleMenu} from "./ui/toggle-menu";
import {handlers} from "./handlers/handlers";
//import DataTable from "datatables.net-dt";

 const main = () => {
     toggleMenu()
     clock()
     createDate()
     createHome()
     handlers()
 }

 main()


//     const currencyService = currenciesService();
//     currencyService.createCurrency("usd", 30)
//     currencyService.createCurrency("eur", 30)
//     currencyService.createCurrency("gbp", 30)
//     currencyService.createCurrency("chf", 30)


$(document).ready(function() {
    console.log("jQuery działa poprawnie!");
});


