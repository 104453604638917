import date from 'date-and-time';
export const clock = () => {
    const time = new Date();
    const godzina = time.getHours();
    const min = time.getMinutes();
    const sek = time.getSeconds();
    const nowIs = ""+godzina+
    ((min<10)?":0":":")+min+
    ((sek<10)?":0":":")+sek;
    document.getElementById("clock").innerHTML = nowIs;
    setTimeout(clock, 1000);
}


export const createDate = ()  => {
    const now = new Date()
    //document.getElementById("day").innerHTML = date.format(now, 'dddd');
    document.getElementById("date").innerHTML = date.format(now, 'DD/MM/YYYY  ');
}



