import BasicComponents from "./basic-components";
import {fillData} from "./table";
import createCurrencyChart from "./chart";
import {fillCurrencyData} from "./currency";

export const createHome = () => {
    const home = new BasicComponents()

    home.createH1("Centrum zarządania")
    home.createP("Powiadomienia")
    home.createActualCurrencyDataContainer()
    home.createDoubleContainer("chart")
    home.createCardChart("chart-left", "Waluty", "myAreaChart", "bar-chart")
    home.createCardChart("chart-right", "Wal2", "myBarChart", "bar-chart")
    home.createTable("mainArea", "ex")
    //createCurrencyChart(250, "myAreaChart");
    //createCurrencyChart(90, "myBarChart");
    fillCurrencyData().catch(error => console.log(error));
    fillData().catch(error => console.log(error));
}






