import BasicComponents from "./basic-components";
import Form from "./form";

export const initBudget = () => {
    const wallet = new BasicComponents()
    const formWallet = new Form()

    wallet.createH1("Budżet")
    wallet.createP("Aby móc zainwestować musisz dokladnie wiedzieć na co wydajesz swoje pieniądze.")
    wallet.createH2("Dodaj swoje srodki i wydatki w ten sposób obliczysz ile masz oszczędności")
    wallet.createDoubleContainer("form")

    formWallet.createform('form-incomes', "form-left")
    formWallet.createFormDiv("left-form-incomes", "left-container card p-4 bg-success bg-gradient", "form-incomes")
    formWallet.createInput("input-name-incomes", "text", "form-control mb-4", "left-form-incomes", "Przychód")
    formWallet.createInput("input-value-incomes", "text", "form-control mb-4", "left-form-incomes", "Kwota")
    formWallet.createButton("buttIncome", "btn btn-warning tx-uppercase bg-gradient ", "Dodaj przychód +", "submit", "left-form-incomes")

    formWallet.createform('form-outgoings', "form-right")
    formWallet.createFormDiv("right-form-outgoings", "right-container card p-4 bg-danger bg-gradient", "form-outgoings")
    formWallet.createInput("input-name-outgoings", "text", "form-control mb-4", "right-form-outgoings", "Wydatek")
    formWallet.createInput("input-valu-outgoings", "text", "form-control mb-4", "right-form-outgoings", "Kwota")
    formWallet.createButton("buttOutgoig", "btn btn-warning tx-uppercase bg-gradient ", "Dodaj wydatek -", "submit", "right-form-outgoings")

    //wallet.createDoubleContainer("budget-tables")
    //formWallet.createFormDiv("incomes-tables", "incomes-tables", "budget-tables" )

    const formLeft = document.getElementById("form-left")

    const formIncomes = document.getElementById("form-incomes")

    const deleteIncome = (keyIncome) => {
            localStorage.removeItem(keyIncome)
            document.getElementById(keyIncome).remove()
    }


    const createIncome = (keyIncome, income) => {
        const div = document.createElement("div")
        div.innerText = income.name + ": " + income.value
        div.className = "single-income"
        div.id = keyIncome
        formLeft.appendChild(div)
        const button = document.createElement("button")
        button.innerText = "x"
        button.className = "button btn btn-success"
        button.onclick = () =>  {deleteIncome(keyIncome)}
        div.appendChild(button)
    }

    const initIncomes = () => {
        const regex = /^in\d{13,}$/;
        const incomesKeys = (Object.keys(localStorage));

        incomesKeys.filter(key => regex.test(key)).forEach(key => {
            //console.log(localStorage.getItem(key))
            const income = JSON.parse(localStorage.getItem(key))
            // console.log(income.name, income.value);
            createIncome(key, income)
        })
    }


    const countIncomes = () => {
        const regex = /^in\d{13,}$/;
        const incomesKeys = (Object.keys(localStorage)) || [];

        const toSumArr = incomesKeys
            .filter(key => regex.test(key))
            .map(key => {
            const income = JSON.parse(localStorage.getItem(key))
            return Number.parseFloat(income.value)
            }).reduce((a, b) => a + b)

        console.log(toSumArr)
    }

    initIncomes()
    //countIncomes()


    formIncomes.addEventListener("submit", (e) => {
        e.preventDefault();

        const keyIncome = "in" + Date.now().toString();
        const name = document.getElementById("input-name-incomes").value
        const value = document.getElementById("input-value-incomes").value
        const income = {name: name, value: value};

        if(name.length < 1) {
            alert("Wypełnij pole przychodu")
        } else if (value.length  < 1) {
            alert("Wypełnij pole kwoty")
        }else {
            localStorage.setItem(keyIncome, JSON.stringify(income));
            createIncome(keyIncome, income);
        }

        document.getElementById("input-name-incomes").value = ""
        document.getElementById("input-value-incomes").value = ""

    })

    const formOutgoing = document.getElementById("form-outgoings")
    console.log(formOutgoing);
    formOutgoing.addEventListener("submit", (e) => {
        e.preventDefault();
        console.log('działa outgo')
    })


}

