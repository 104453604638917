import {fetchCurrencyData} from "../api/get-data";
import ApexCharts, {options} from 'apexcharts'
import {Axios} from "axios";

const last = 250
let tabela = []


    Promise.all([
        fetchCurrencyData("usd", last),
        fetchCurrencyData("eur", last),
        fetchCurrencyData("gbp", last),
        fetchCurrencyData("chf", last)])
        .then((values) => {
            //console.log("test", values);
            tabela = values
            return values;
        })



//console.log("tututututututututututututut", arr)



export default async function createCurrencyChart(last, id) {
    //const last = 250; // Ilość ostatnich notowań
    const currencyDataUSD = await fetchCurrencyData("usd", last);
    const currencyDataEUR = await fetchCurrencyData("eur", last);
    const currencyDataCHF = await fetchCurrencyData("chf", last);
    const currencyDataGBP = await fetchCurrencyData("gbp", last);

    console.log(currencyDataUSD)

    // console.log(currencyData);
    // Pobranie dat i kursów z odpowiedzi API
    const dates = currencyDataUSD.data.rates.map(rate => rate.effectiveDate);
    const USDrates = currencyDataUSD.data.rates.map(rate => rate.mid);
    const EURrates = currencyDataEUR.data.rates.map(rate => rate.mid);
    const CHFrates = currencyDataCHF.data.rates.map(rate => rate.mid);
    const GBPrates = currencyDataGBP.data.rates.map(rate => rate.mid);

    const chartSeries = [
        {
            name: `GBP`,
            data: GBPrates
        },

        {
            name: `CHF`,
            data: CHFrates
        },

        {
            name: `EUR`,
            data: EURrates
        },

        {
            name: `USD`,
            data: USDrates
        },

    ]

    const options = {
        series: chartSeries,
        chart: {
            height: 350,
            type: 'line'
        },
        dataLabels: {
            enabled: false
        },
        // stroke: {
        //     curve: 'smooth'
        // },
        xaxis: {
            type: 'datetime',
            categories: dates
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy'
            },
        }
    };


    const chart = new ApexCharts(document.getElementById(id), options);

    chart.render()

    //await chart2.render()
}

// const a = async () => {
//
//     console.log("DOM w pełni załadowany!");
// }

