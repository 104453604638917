import axios from 'axios';
import DataTable from "datatables.net-dt";

export async function fetchCurrencyData(currency, last) {
    try {
        const response = await axios.get(`https://api.nbp.pl/api/exchangerates/rates/a/${currency}/last/${last}/?format=json`);
        console.log("tetete", response);
        return response
    } catch (error) {
        console.error('Wystąpił błąd!', error);
    }
}

export async function fetchTableData(table) {
    try {
        const response = await axios.get(`https://api.nbp.pl/api/exchangerates/tables/${table}`);
        console.log("to tutaj", response.data);
        return response
    } catch (error) {
        console.error('Wystąpił błąd!', error);
    }
}










