export default class Form{
    #mainArea

    constructor() {
        this.#mainArea = document.getElementById('mainArea');
    }

    createform(id, parentId) {
        const parrent = document.getElementById(parentId);
        const form = document.createElement("form");
        form.id = id;
        form.className = "form mt-4";
        parrent.append(form);
    }

    createFormDiv(id, divClass, parentId){
        const parrent = document.getElementById(parentId);
        const div = document.createElement("div");
        div.id = id;
        div.className = divClass;
        parrent.append(div);
    }

    createLabel(text, id, parentId){
        const parrent = document.getElementById(parentId);
        const label = document.createElement("label");
        label.id = id;
        label.className = "form-label";
        label.innerHTML = text;
        parrent.append(label);
    }

    createInput(id, type, classInput, parentId, placeholder) {
        const input = document.createElement("input");
        const parrent = document.getElementById(parentId);
        input.id = id;
        input.type = type;
        input.className = classInput;
        input.placeholder = placeholder;
        parrent.appendChild(input);
    }

    createSelect(id, classInput, parentId, options) {
        const select = document.createElement("select");
        const parent = document.getElementById(parentId);
        select.id = id;
        select.className = classInput;

        options.forEach(optionText => {
            const option = document.createElement("option");
            option.value = optionText;
            option.textContent = optionText;
            select.appendChild(option);
        });

        parent.appendChild(select);
    }



    createButton( id, classButton, text, type, parentId ) {
        const button = document.createElement("button");
        const parrent = document.getElementById(parentId);
        button.id = id;
        button.innerHTML = text;
        button.className = classButton;
        button.type = type;
        parrent.append(button);
    }


}