import {fetchTableData} from "../api/get-data";

export const fillCurrencyData = async () => {

    const res = await fetchTableData("A");

    const firstCard = document.getElementById("curr-card-firts")
    const secondCard = document.getElementById("curr-card-seconds")
    const thirdCard = document.getElementById("curr-card-thirds")
    const fourthCard = document.getElementById("curr-card-fourth")

    firstCard.innerText = `Funt: ${res.data[0].rates[10].mid}`
    secondCard.innerText = `Euro ${res.data[0].rates[7].mid}`
    thirdCard.innerText = `Frank ${res.data[0].rates[9].mid}`
    fourthCard.innerText = `Dolar ${res.data[0].rates[1].mid}`

}