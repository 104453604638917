import BasicComponents from "./basic-components";
import {fillCurrencyData} from "./currency";
import Form from "./form";

export const initWallet = () => {
    const wallet =  new BasicComponents()

    localStorage.setItem("saves", "10000")

    wallet.createH1("Portfel ivestycyjny")
    wallet.createP("To jest twój symulacyjny protfel możesz podać ile pienidzy chcesz zainwestować")
    const saves = localStorage.getItem("saves")

    wallet.createH2(`W tej chwili masz ${saves}`)

    const walletForm = new Form()

    //wallet.createDiv("wallet-container" , "container")

    walletForm.createFormDiv("wallet-container", "container p-4 bg-primary", "mainArea" )
    walletForm.createform("wallet-form", "wallet-container" )
    walletForm.createLabel("Tutaj podaj jaka kwotę chcesz zainwestować", "labPodaj", "wallet-form")
    walletForm.createInput("choose", "select", "select input form-control mb-4", "wallet-form", "np 500.00" )
    walletForm.createLabel("Tutaj wybierz opcje w co chesz zainwestowac", "labPodaj", "wallet-form")
    walletForm.createSelect("investSelect", "form-control mb-4", "wallet-form", ["Option 1", "Option 2", "Option 3"]);
    walletForm.createButton("submit-invest", "btn btn-warning bg-gradient ", "Zatwierdz ivestycje", "submit", "wallet-form")













    //fillCurrencyData().catch(console.error)

}