import {createHome} from "../ui/home";
import createCurrencyChart from "../ui/chart";
import {fillData} from "../ui/table";
import ApexCharts from "apexcharts";
import {options} from "../ui/chart";
import {initWallet} from "../ui/wallet";
import {initBudget} from "../ui/budget";
import {initInstruction} from "../ui/instruction";



export const handlers = () => {
    const buttHome = document.getElementById("home")
    buttHome.addEventListener("click", (e) => {
        document.getElementById("mainArea").innerHTML = ""
        createHome()
    })

    const buttWallet = document.getElementById("wallet")
    buttWallet.addEventListener("click", (e) => {
        document.getElementById("mainArea").innerHTML = ""
        initWallet()
    })

    const buttBudget = document.getElementById("budget")
    buttBudget.addEventListener("click", (e) => {
        document.getElementById("mainArea").innerHTML = ""
        initBudget()
    })

    const buttIns = document.getElementById("instruction")
    buttIns.addEventListener("click", (e) => {
        document.getElementById("mainArea").innerHTML = ""
        initInstruction()
    })


    const form = document.getElementById("walletForm")
    form.addEventListener('submit', (event) => {
        event.preventDefault();
    });



    // const buttAddOutgoing = document.getElementById("buttOutgoig")
    // buttAddOutgoing.addEventListener("submit", (e) => {
    //     e.preventDefault();
    // })

}