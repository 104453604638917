import {getElement} from "bootstrap/js/src/util";
import {createElement} from "simple-datatables";

export default class BasicComponents {
    #mainArea

    constructor() {
        this.#mainArea = document.getElementById('mainArea');
        // this.#currentValue = currentValue;
        // this.#beforeValue = beforeValue;
        // this.#no = no;
        // this.#date = date;
    }

    createH1(title = "Title") {
        const h1 = document.createElement("h1");
        h1.innerText = title;
        h1.className = "mt-4";
        this.#mainArea.append(h1);
    }

    createH2(title = "Title") {
        const h2 = document.createElement("h2");
        h2.innerText = title;
        h2.className = "mt-4";
        this.#mainArea.append(h2);
    }

    createP(text = "Title") {
        const paragraphElement = document.createElement("p");
        paragraphElement.innerText = text;
        this.#mainArea.append(paragraphElement);
    }

    createDiv(divId, divClass) {
        const div = document.createElement("div");
        if (divClass) div.className = divClass;
        if (divId) div.id= divId;
        return div;
    }

    createDoubleContainer(id) {
        const row = this.createDiv(id+"row", "row");
        this.#mainArea.append(row)
        const leftContainer = this.createDiv(id + "-left", "col-xl-6")
        const rightContainer = this.createDiv(id + "-right", "col-xl-6")
        row.append(leftContainer);
        row.append(rightContainer);
    }


    createCardChart(element, header = "Title", id, iconName){
        const container =document.getElementById(element)
        const card = this.createDiv("card-" + id, "card mb-4");
        container.append(card);
        const cardHeader = this.createDiv("card-header-" + id, "card-header");
        const icon = document.createElement("i");
        icon.className = `fas fa-${iconName} me-1`;
        cardHeader.append(icon);
        cardHeader.append(header);
        card.append(cardHeader);
        const cardBody = this.createDiv(id, "card-body");
        card.append(cardBody);
    }

    createTable(element, id){
        const el = document.getElementById(element);
        const table = document.createElement("table");
        table.id = id
        table.classList.add("table-striped")
        el.append(table);
        return table;
    }

    createActualCurrencyDataContainer() {
        const row = this.createDiv("row-actual-currency", "row");
        this.#mainArea.append(row)
        const firstCol = this.createDiv("curr-firts", "col-xl-3 col-md-6")
        const secondCol = this.createDiv("curr-second", "col-xl-3 col-md-6")
        const thirdCol = this.createDiv("curr-third", "col-xl-3 col-md-6")
        const fourthCol = this.createDiv("curr-fouth", "col-xl-3 col-md-6")

        row.append(firstCol)
        row.append(secondCol)
        row.append(thirdCol)
        row.append(fourthCol)

        const firstCard = this.createDiv("curr-card-firts", "card bg-primary text-white mb-4 p-3 text-center")
        const secondCard  = this.createDiv("curr-card-seconds", "card bg-warning text-white mb-4 p-3 text-center")
        const thirdCard = this.createDiv("curr-card-thirds", "card bg-success text-white mb-4 p-3 text-center")
        const fourthCard = this.createDiv("curr-card-fourth", "card bg-danger text-white mb-4 p-3 text-center")

        firstCol.append(firstCard)
        secondCol.append(secondCard)
        thirdCol.append(thirdCard)
        fourthCol.append(fourthCard)
    }


}